import {Fragment} from 'react'
import ContactUsForm from '../components/ContactUsForm';
import { Helmet } from 'react-helmet';

// import required modules


export default function Contact() {
      
    return <Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FTD | Contact Us</title>
      <meta name="description" content="Connect with Flawless Tech Data through our contact us page. Reach out to us for inquiries, support, or any assistance you may need. We are here to help." />
      <meta name="keywords" content="data protection, user privacy, personal information, data privacy, privacy terms, Flawless Tech Data privacy, FTD privacy, information security, data usage, privacy agreement, user data policy, Flawless Tech Data data protection, FTD data protection" />
    </Helmet>

            <div className="page-heading">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="header-text">
                      <h2>Contact Us</h2>
                      <div className="div-dec" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ***** Main Banner Area End ***** */}
            <section className="map">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div id="map">
                      {/* <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2791.78997548554!2d144.9805125252687!3d-37.84132841005892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6681f3e9cb7e1%3A0x9d52778f56cab5a8!2sFawkner%20Park!5e1!3m2!1sen!2sth!4v1648201596364!5m2!1sen!2sth" width="100%" height="450px" frameBorder={0} style={{border: 0, borderRadius: 5, position: 'relative', zIndex: 2}} allowFullScreen /> */}
                    </div>
                  </div>
                  <div className="col-lg-10 offset-lg-1">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="info-item">
                          <i className="fa fa-envelope" />
                          <h4>Email Address</h4>
                          {/* <a href="#"> */}
                          contact@flawlesstechdata.com
                          {/* </a> */}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="info-item">
                          <i className="fa fa-phone" />
                          <h4>Phone Number</h4>
                          {/* <a href="#"> */}
                          044-22790300
                            {/* </a> */}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="info-item">
                          <i className="fa fa-map-marked-alt" />
                          <h4>Address</h4>
                          {/* <a href="#"> */}
                          141 Velachery Main Road, East Tambaram, Chennai 600059
                            {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div>
            <ContactUsForm />
            </div>
  

    </Fragment>

}