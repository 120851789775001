import {Fragment} from 'react'
import { Helmet } from 'react-helmet';

// import required modules


export default function Home() {
      
    return <Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FTD | Services</title>
      <meta name="description" content="Flawless Tech Data offers specialized services including industry-specific data collection, master data management (MDM), and web scraping. Our tailored solutions enhance data accuracy, optimize business operations, and provide valuable insights to drive your growth." />
      <meta name="keywords" content="store location data, location data collection, retail location data, store data management, location intelligence, store locator data, location analytics, retail data collection, geographic data collection, store mapping, location-based data, store location analysis, retail data management, store position data, location data services, industry-specific companies, business contacts, B2B contacts, industry data, company directories, industry-specific data, business leads, company profiles, industry contact lists, targeted business contacts, company contact information, sector-specific companies, industry networking, business data, contact management, master data management, MDM solutions, data governance, data quality management, enterprise data management, data management services, MDM software, data integration, data management strategy, master data governance, data stewardship, data accuracy, master data solutions, data synchronization, data management platform, web scraping, data extraction, web data scraping, web crawler, web scraping services, data scraping, automated data collection, web harvesting, web scraping tools, data mining, online data extraction, scraping technology, web data extraction, web scraping API, web scraping solutions, lead generation, B2B lead generation, lead generation strategies, sales leads, lead generation services, lead capture, lead nurturing, lead management, lead generation tools, online lead generation, marketing leads, lead acquisition, lead generation software, inbound lead generation, outbound lead generation" />
      {/* <meta name="author" content="Your Name" /> */}
    </Helmet>
        
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="header-text">
                  <h2>Our Services</h2>
                  <div className="div-dec" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Main Banner Area End ***** */}
        
        <section className="what-we-do">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="left-content">
                  <h4 style={{color: "#43ba7f"}}><i>About Our Services</i></h4>
                  <p><b>Flawless Tech Data</b> is a premier technology solutions provider specializing in advanced application and website development. Leveraging a rich blend of technologies such as Python, HTML, Kivy, and other modern frameworks, we deliver tailored digital solutions that meet the diverse needs of our clients.<br /><br />At <b>Flawless Tech Data</b>, we are dedicated to crafting innovative and high-performance applications and websites that drive success and enhance user experiences. Our team of skilled professionals combines technical expertise with a deep understanding of client goals to create impactful, user-centric solutions.</p>
                
                </div>
              </div>

              <div className="col-lg-6">
                <div className="right-items">
                <h4 style={{color: "#ff511a"}}>Core Services</h4><br />
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="item">
                        <em>01</em>
                        <br />
                        <h4>Web Scraping</h4>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item">
                        <em>02</em>
                        <br />
                        <h4>Lead Generation</h4>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item">
                        <em>03</em>
                        <br />
                        <h4>Store Location Data Collection</h4>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item">
                        <em>04</em>
                        <br />
                        <h4>Master Data Management</h4>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item">
                        <em>05</em>
                        <br />
                        <h4>Premium<br />Industry-Specific Data</h4>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item">
                        <em>06</em>
                        <br />
                        <h4>Webpage <br /> and <br />Mobile Application</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="main-services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-item">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="left-image">
                        <img src="assets/images/service-image-02.jpg" alt="web service" />
                      </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                      <div className="right-text-content">
                      <i><img src="assets/images/web_icon.png" alt="web icon" /></i>
                        <h4>Web Scraping</h4>
                        <p>We provide advanced web scraping services using tools and technologies such as Python, Perl, and JavaScript. Our solutions deliver accurate and near real-time data extraction, offering valuable insights while ensuring data confidentiality and security.</p>
                        <div className="buttons">
                            <br />
                          <div className="orange-button">
                            <a href="web-scraping">
                                Discover More
                            </a>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="service-item">
                  <div className="row">
                    <div className="col-lg-6 align-self-center">
                      <div className="left-text-content">
                      <i><img src="assets/images/lead_icon.png" alt="lead icon" /></i>
                        <h4>Lead Generation</h4>
                        <p>Our lead generation process utilizes leading tools and diverse sources to capture high-quality, up-to-date leads. We focus on real-time data collection and qualification to provide you with actionable leads that drive your business growth.</p>
                        <div className="buttons">
                            <br />
                          <div className="orange-button">
                            <a href="lead-generation">
                                Discover More
                            </a>
                          </div>
                        </div>
                     
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="right-image">
                        <img src="assets/images/service-image-01.jpg" alt="lead service" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="service-item">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="left-image">
                        <img src="assets/images/service-image-03.jpg" alt="store location service" />
                      </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                      <div className="right-text-content">
                        <i><img src="assets/images/store_icon.png" alt="store icon" /></i>
                        
                        <h4>Store Location Data Collection</h4>
                        <p>We specialize in collecting and analyzing store location data to help businesses make informed decisions. Our services include mapping and aggregating location information to support your strategic planning and operational needs.</p>
                        <div className="buttons">
                            <br />
                          <div className="orange-button">
                            <a href="store-location">
                                Discover More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="service-item">
                  <div className="row">
                    <div className="col-lg-6 align-self-center">
                      <div className="left-text-content">
                      <i><img src="assets/images/mdm_icon.png" alt="mdm icon" /></i>
                        <h4>Master Data Management</h4>
                        <p>we offer advanced Master Data Management (MDM) solutions designed to centralize and streamline your critical business information. Our MDM services are crafted to ensure that your data is consistent, accurate, and reliable across your entire organization.</p>
                        <div className="buttons">
                            <br />
                          <div className="orange-button">
                            <a href="master-data-management">
                                Discover More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="right-image">
                        <img src="assets/images/service-image-04.jpg" alt="MDM service" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="service-item">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="left-image">
                        <img src="assets/images/service-image-05.jpg" alt="Industry specific service" />
                      </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                      <div className="right-text-content">
                        <i><img src="assets/images/isd_icon.png" alt="industry specific icon" /></i>
                        
                        <h4>Premium Industry-Specific Data</h4>
                        <p>we provide premium industry-specific data designed to cater to the distinct needs of various sectors. Our comprehensive and precise datasets offer a wealth of information, delivering critical insights that empower your business to excel.</p>
                        <div className="buttons">
                            <br />
                          <div className="orange-button">
                            <a href="industry-specific-data">
                                Discover More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="service-item">
                  <div className="row">
                    <div className="col-lg-6 align-self-center">
                      <div className="left-text-content">
                      <i><img src="assets/images/app_icon.png" alt="" /></i>
                        <h4>Webpage &amp; Mobile Application Development</h4>
                        <p>At Flawless Tech Data, we specialize in crafting seamless and high-performance websites and mobile applications tailored to your unique needs. Our approach focuses on designing intuitive digital solutions that not only elevate user experience but also align with your business objectives. By leveraging cutting-edge technology and innovative design principles, we ensure that every project we undertake enhances functionality, usability, and overall effectiveness. Whether you need a sophisticated website or a dynamic mobile app, our team is dedicated to delivering exceptional results that drive your success and meet the highest standards of quality.</p>
                        <div className="buttons">
                            <br />
                          <div className="orange-button">
                            <a href="development">
                                Discover More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="right-image">
                        <img src="assets/images/service-image-06.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
              
  
    </Fragment>

}