import {Fragment} from 'react'
import { Helmet } from 'react-helmet';

// import required modules


export default function Privacy() {
      
    return <Fragment><Helmet>
    <meta charSet="utf-8" />
    <title>FTD | Privacy Policy</title>
    <meta name="description" content="ure your privacy at Flawless Tech Data. Safeguarding your information is our top priority." />
    <meta name="keywords" content="data protection, user privacy, personal information, data privacy, privacy terms, Flawless Tech Data privacy, FTD privacy, information security, data usage, privacy agreement, user data policy, Flawless Tech Data data protection" />
  </Helmet>
  
        
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="header-text">
                  <h2>Privacy Policy</h2>
                  <div className="div-dec" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Main Banner Area End ***** */}

         

        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div><b>Effective Date: [1-Sep-2024]</b></div><br /><br />
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>1. Introduction</h4>
                  <p>
                  Welcome to Flawless Tech (“we,” “our,” or “us”). At Flawless Tech, safeguarding your privacy is a top priority. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website [insert website URL], use our services, or interact with us. By using our website or services, you agree to the practices described in this policy.
				  </p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>2. Information We Collect</h4>
                  <h3 style={{color:"#43ba7f"}}>2.1. Company and Contact Data</h3>
                  <p>
					<ul>
						<li><strong> • Company Names:</strong> The name of the company you represent or are affiliated with.</li><br />
						<li><strong> • Contact Names:</strong> The names of individuals we interact with within your company.</li><br />
						<li><strong> • Email Addresses:</strong> Contact email addresses used for communication.</li><br />
						<li><strong> • Phone Numbers:</strong> Contact phone numbers provided for business purposes.</li><br />
						<li><strong> • Job Titles:</strong> The professional titles of individuals associated with the company.</li>
						<li><strong> • Company Addresses:</strong> The physical addresses of your company’s offices or locations.</li>
					</ul>
				  </p>
                  <h3 style={{color:"#43ba7f"}}>2.2. Technical Data</h3>
                  <p>
					<ul>
						<li><strong> • IP Address:</strong> A numerical label assigned to your device by your Internet Service Provider (ISP).</li><br />
						<li><strong> • Browser Type and Version:</strong> Information about the web browser you are using.</li><br />
						<li><strong> • Operating System:</strong> The software platform running on your device (e.g., Windows, macOS, Linux).</li><br />
						<li><strong> • Referring Website:</strong> The URL of the site that directed you to our website.</li><br />
						<li><strong> • Pages Viewed:</strong> The specific pages you visit on our site.</li><br />
						<li><strong> • Time and Date:</strong> The time and date of your visit and interactions with our site.</li><br />
					</ul>
				  </p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>3. How We Use Your Information</h4>
                  <p>
					<ul>
						<li><strong> • Provision of Services:</strong> To deliver and manage the services you have requested from us.</li><br />
						<li><strong> • Service Improvement:</strong> To enhance and refine our services based on user feedback and usage patterns.</li><br />
						<li><strong> • Communication:</strong> To communicate with you about updates, offers, and other information related to our services. This includes responding to inquiries and providing customer support.</li><br />
						<li><strong> • Data Analysis:</strong> To analyze usage trends and preferences to improve user experience and the functionality of our website and services.</li><br />
						<li><strong> • Legal Compliance:</strong> To comply with legal obligations and enforce our terms of service, including addressing any violations or disputes.</li><br />
					</ul>
				  </p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>4. How We Share Your Information</h4>
                  <p>
					<ul>
                        <li><strong> • Service Providers:</strong> We may disclose your information to third-party vendors and service providers who perform functions on our behalf, such as data storage, processing, and customer support. These parties are contractually obligated to protect your information and use it only for the purposes for which we engage them.</li><br />
                        <li><strong> • Legal Requirements:</strong> We may disclose your information if required to do so by law, regulation, or legal process. This includes responding to requests from government authorities or law enforcement.</li><br />
                        <li><strong> • Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, we may transfer your information to the acquiring entity. We will notify you of any such change in ownership or control of your personal information.</li><br />
                    </ul>
				  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>5. Data Security</h4>
                  <p>We implement robust security measures to protect your personal information from unauthorized access, use, or disclosure. This includes using encryption, access controls, and secure servers. Despite our efforts, no security system is completely infallible. We cannot guarantee the absolute security of your information and encourage you to take precautions to protect your personal data.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>6. Quality at Every Level</h4>
                  <p>
					<ul>
                        <li><strong> • Access:</strong> The right to request access to the personal data we hold about you.</li><br />
                        <li><strong> • Correction:</strong> The right to request correction of any inaccurate or incomplete data.</li><br />
                        <li><strong> • Deletion:</strong> The right to request the deletion of your personal data, subject to certain conditions and exceptions.</li><br />
                        <li><strong> • Restriction:</strong> The right to request restriction on the processing of your data under specific circumstances.</li><br />
                        <li><strong> • Objection:</strong> The right to object to the processing of your data for specific purposes, including direct marketing.</li><br />
                        <li>To exercise any of these rights, please contact us using the information provided below. We will respond to your request in accordance with applicable data protection laws.</li><br />
                    </ul>
				  </p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>7. Cookies and Tracking Technologies</h4>
                  <p>Our website uses cookies and similar tracking technologies to enhance your browsing experience. Cookies are small data files placed on your device that help us remember your preferences and track usage patterns. You can manage your cookie preferences through your browser settings. For more detailed information about our use of cookies and how to manage them, please refer to our Cookie Policy [insert link].</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>8. Changes to This Privacy Policy</h4>
                  <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will post any updates on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>9. Contact Us</h4>
                  <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us:</p>
                  
                  <h3 style={{color:"#43ba7f"}}>Flawless Tech Data</h3>
                  <p>
					<ul>
						<li><strong> Email:</strong> contact@flawlesstechdata.com</li><br />
						<li><strong> Address:</strong> Flawless Tech Data – 141 Velachery Main Road, East Tambaram, Chennai, Tamil Nadu - 600056</li><br />
					</ul>
				  </p>
                  
 
                </div>
              </div>
              
            </div>
          </div>
        </section>
        

    </Fragment>

}