import {Fragment} from 'react'

// import required modules


export default function Disclaimer() {
      
    return <Fragment>
        
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="header-text">
                  <h2>Disclaimer</h2>
                  <div className="div-dec" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Main Banner Area End ***** */}

         

        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div><b>Disclaimer for Data Integrity</b></div><br /><br />
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>1. Data Accuracy and Integrity</h4>
                  <p>The information provided on [insert website URL] (the "Website") by Flawless Tech (“we,” “our,” or “us”) is for general informational purposes only. While we strive to ensure that the data and content provided on this Website are accurate and up-to-date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, or availability of the data or content.</p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>2. No Guarantee of Accuracy</h4>
                    <p>The data and information presented on the Website may be subject to change without notice. We do not warrant that the data or content is current or complete. The information is provided on an “as-is” basis and may contain errors or inaccuracies. We disclaim all liability for any errors or omissions in the data or content provided.</p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>3. Reliance on Information</h4>
                  <p>Any reliance you place on the information provided on this Website is strictly at your own risk. We will not be liable for any loss or damage, including but not limited to direct, indirect, incidental, consequential, or punitive damages, arising out of or in connection with your reliance on the data or content provided on this Website.</p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>4. External Links</h4>
                  <p>Our Website may contain links to external websites or resources that are not under our control. We provide these links for convenience, and their inclusion does not imply endorsement of the linked sites or their content. We are not responsible for the accuracy, legality, or reliability of any external websites or resources, and we disclaim all responsibility for any loss or damage that may arise from your use of such sites.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>5. Data Security</h4>
                  <p>While we implement reasonable measures to protect the data we collect and process, we cannot guarantee the security of any data transmitted to or from our Website. The transmission of data over the internet is inherently vulnerable, and we are not liable for any unauthorized access or use of data.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>6. No Professional Advice</h4>
                  <p>The data and content on our Website do not constitute professional advice. Any decisions you make based on the data provided are your own responsibility. We recommend consulting with appropriate professionals for advice tailored to your specific situation.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>7. Changes to Data</h4>
                  <p>We reserve the right to modify or update the data and content on our Website at any time without prior notice. We are under no obligation to update the data or content, and any changes will be effective immediately upon posting.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>8. Limitation of Liability</h4>
                  <p>To the fullest extent permitted by law, Flawless Tech and its affiliates, officers, directors, employees, and agents disclaim all liability for any loss or damage arising out of or related to the use of our Website and the data or content provided, including but not limited to errors or omissions in the data, any loss of data, or any interruptions in service.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>9. Contact Us</h4>
                  <p>If you have any questions or concerns regarding this disclaimer or the data provided on our website, please contact us at:</p>
                  
                  <h3 style={{color:"#43ba7f"}}>Flawless Tech Data</h3>
                  <p>
					<ul>
						<li><strong> Email:</strong> contact@flawlesstechdata.com</li><br />
						<li><strong> Address:</strong> Flawless Tech Data – 141 Velachery Main Road, East Tambaram, Chennai, Tamil Nadu - 600056</li><br />
					</ul>
				  </p>
                  
 
                </div>
              </div>
              
            </div>
          </div>
        </section>
        

    </Fragment>

}