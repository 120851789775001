import {Fragment} from 'react'
import { Helmet } from 'react-helmet';

// import required modules


export default function LeadGeneration() {
      
    return <Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FTD | Lead Generation</title>
      <meta name="description" content="Boost your sales with our expert lead generation services. We specialize in B2B lead generation, offering effective strategies to find and nurture high-quality sales leads." />
      <meta name="keywords" content="lead generation, B2B lead generation, lead generation strategies, sales leads, lead generation services, lead capture, lead nurturing, lead management, lead generation tools, online lead generation, marketing leads, lead acquisition, lead generation software, inbound lead generation, outbound lead generation" />
      {/* <meta name="author" content="Your Name" /> */}
    </Helmet>
        
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="header-text">
                  <h2>Lead Generation</h2>
                  <div className="div-dec" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Main Banner Area End ***** */}

        
        <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 offset-lg-1">
              <div className="naccs">
                <div className="tabs">
                  <div className="row">
                    <div className="col-lg-12">
                      <ul className="nacc">
                        <li className="active">
                          <div>
                            <div className="left-image">
                              <img src="assets/images/service-details-01.jpg" alt="lead generation" />
                            </div>
                            <div className="right-content">
                              <h4>Targeted Lead Generation</h4>
                              <p>At <b>Flawless Tech Data</b>, we specialize in advanced lead generation processes designed to identify and capture high-quality leads with precision and efficiency. Utilizing leading lead generation tools and a variety of sources, we provide real-time, up-to-date information to help your business stay ahead in a competitive market.</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-11 offset-lg-1">
                <div className="center-content">
                  <h4 style={{color:"#ff511a"}}>Our Lead Generation Process Includes</h4>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#43ba7f"}}>Cutting-Edge Tools and Techniques</h4>
                  <p>
					          <ul>
					          	<li><strong>Advanced Tools:</strong> We leverage top-tier lead generation tools and platforms to automate and enhance the process of finding and qualifying leads. Our toolkit includes industry-leading software for data scraping, CRM integration, and lead enrichment.</li><br />
					          	<li><strong>Diverse Sources:</strong> By tapping into a wide range of online and offline sources, including social media, industry directories, and web databases, we ensure comprehensive coverage and capture leads from multiple channels.</li><br />
					          </ul>
				          </p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-3">
                <div className="right-content">
                  <h4 style={{color:"#43ba7f"}}>Near Real-Time Data Collection</h4>
                  <p>
					          <ul>
					          	<li><strong>Timely Updates:</strong> Our lead generation process focuses on providing near real-time data collection. This approach ensures that the information we deliver is current and reflects the latest market conditions and opportunities.</li><br />
					          	<li><strong>Dynamic Data Handling:</strong> We use real-time data processing techniques to keep our lead information fresh and relevant, helping you make informed decisions based on the most recent data.</li><br />
					          </ul>
				          </p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#43ba7f"}}>Confidentiality and Data Security</h4>
                  <p>
					          <ul>
					          	<li><strong>Confidential Handling:</strong> We prioritize the confidentiality of your leads and sensitive data. Our processes are designed to protect your information, ensuring that it is handled securely and only shared with authorized stakeholders.</li><br />
					          	<li><strong>Data Privacy:</strong> We adhere to stringent data protection standards and privacy regulations, safeguarding your data throughout the lead generation process.</li><br />
					          </ul>
				          </p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-3">
                <div className="right-content">
                  <h4 style={{color:"#43ba7f"}}>Lead Qualification and Enrichment</h4>
                  <p>
					          <ul>
					          	<li><strong>Quality Assurance:</strong> We not only gather leads but also qualify and enrich them to ensure they meet your specific criteria. This includes verifying contact details, assessing lead quality, and providing additional insights that enhance lead value.</li><br />
					          	<li><strong>Custom Filtering:</strong> Our services include filtering leads based on your business needs and target audience, delivering highly relevant and actionable leads.</li><br />
					          </ul>
				          </p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#43ba7f"}}>Comprehensive Reporting and Analytics</h4>
                  <p>
					          <ul>
					          	<li><strong>Detailed Reports:</strong> We provide detailed reports on lead generation activities, including key metrics and insights into lead performance. This enables you to track the effectiveness of our lead generation efforts and make data-driven decisions.</li><br />
					          	<li><strong>Performance Analysis:</strong> Our analytics help you understand lead sources, conversion rates, and other critical factors, allowing you to refine your marketing strategies.</li><br />
					          </ul>
				          </p>
                </div>
              </div>
            </div>
            <img src="assets/images/LeadsFlowchart.png"  style={{width:"45%"}} alt="lead generation" />
            <div className="row">
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}><br />Why Choose Flawless Tech Data for Lead Generation?</h4>
                  <p>
					        <ul>
					        	<li><strong>• Expertise:</strong> Our team utilizes advanced tools and methodologies to deliver high-quality leads tailored to your business needs.</li><br />
					        	<li><strong>•	Real-Time Data:</strong> We ensure that you receive the most current and relevant lead information, helping you stay ahead of the competition.</li><br />
					        	<li><strong>•	Confidentiality:</strong> We uphold the highest standards of data privacy and security, protecting your leads and sensitive information.</li><br />
					        	<li><strong>•	Customization:</strong> Our lead generation process is designed to meet your specific requirements, providing you with valuable, actionable leads.</li>
					        </ul>
				          </p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="center-content">
                  <br /><p>Drive your business growth with <b>Flawless Tech Data’s</b> expert lead generation services. Contact us today to learn how our innovative approach can help you capture high-quality leads and achieve your sales objectives.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        

    </Fragment>

}