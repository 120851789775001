import {Fragment} from 'react'

// import required modules


export default function Footer() {
      
    return <Fragment>
        
        <section className="footer-top">
          <div className="container">
            <div className="row">
            <div className="col-lg-4 offset-lg-1">
                  <h4>Contact</h4>
                  <i className='fa fa-phone' /> <p>044-22790300</p>
                  <i className='fa fa-envelope' /> <p>contact@flawlesstechdata.com</p>
                  <i className='fa fa-map-marker' /> <p>141 Velachery Main Road, East Tambaram, Chennai 600059</p>
            </div>
            <div className="col-lg-3 offset-lg-3">
                  <h4>Quick Link</h4>
                  <a href="about-us"><i className='fa-chevron-right' /><p>About Us</p></a>
                  <a href="privacy-policy"><i className='fa-chevron-right' /><p>Privacy policy</p></a>
                  <a href="terms-conditions"><i className='fa-chevron-right' /><p>Terms &amp; Conditions</p></a>
                  <a href="disclaimer"><i className='fa-chevron-right' /><p>Disclaimer</p></a>
            </div>
            </div>
          </div>
        </section>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>Copyright © 2024 <a href="/">Flawless Tech Data</a>. All rights reserved.
                  </p>
              </div>
            </div>
          </div>
        </footer>
  
    </Fragment>
}

