import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactUsForm() {
    const [formData, setFormData] = useState({
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: '',
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Replace with your EmailJS Service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Replace with your EmailJS Template ID
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID    // Replace with your EmailJS User ID
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
        // Clear the form fields
        setFormData({
          name: '',
          phone: '',
          email: '',
          subject: '',
          message: '',
        });
      }, (error) => {
        console.log('FAILED...', error);
        alert('Failed to send the message, please try again later.');
      });
    };

  return (
    <section className="contact-us-form">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h6>Contact Us</h6>
              <h4>Feel free to message us</h4>
            </div>
          </div>
          <div className="col-lg-10 offset-lg-1">
            <form id="contact" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <fieldset>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name..."
                      autoComplete="on"
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </fieldset>
                </div>
                <div className="col-lg-6">
                  <fieldset>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Your Phone..."
                      autoComplete="on"
                      required
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </fieldset>
                </div>
                <div className="col-lg-6">
                  <fieldset>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      pattern="[^ @]*@[^ @]*"
                      placeholder="Your E-mail..."
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </fieldset>
                </div>
                <div className="col-lg-6">
                  <fieldset>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Subject..."
                      autoComplete="on"
                      required
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <textarea
                      name="message"
                      id="message"
                      required
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </fieldset>
                </div>
                <div className="col-lg-12">
                  <fieldset>
                    <button type="submit" id="form-submit" className="orange-button">Send Message</button>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUsForm;
