import {Fragment} from 'react'
import React, { useState } from 'react';

// import required modules


export default function Header() {


      const [isActive, setIsActive] = useState(false);

      const handleMenuToggle = () => {
        // console.log('Menu toggled'+isActive);
        setIsActive(!isActive);
      };


      // State to track which submenu is open
      const [openDropdown, setOpenDropdown] = useState(null);
      // Function to handle the click event
      const handleDropdownClick = (index) => {
        if (openDropdown === null) {
          setOpenDropdown(index); // Close if the same dropdown is clicked again
        } else {
          setOpenDropdown(null); // Open the clicked dropdown
        }
      };

      
    return <Fragment>
        
        {/* ***** Header Area Start ***** */}
        <header className="header-area header-sticky background-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className={`main-nav ${isActive ? 'active' : ''}`} >
                  {/* ***** Logo Start ***** */}
                  <a href="/" className="logo">
                    <img src="/assets/images/FLawless.png" alt="full-logo" />
                  </a>
                  {/* ***** Logo End ***** */}
                  {/* ***** Menu Start ***** */}
                  <ul className={`nav ${isActive ? 'active' : ''}`}>
                    <li className="scroll-to-section"><a href="/" >Home</a></li>
                    <li className={`has-sub `}>
                      <a href="our-services">
                        Services
                      </a><i className={`sub-menu ${openDropdown === null ? 'fa-chevron-down' : 'fa-chevron-up'}`} onClick={handleDropdownClick}/>
                      <ul className={`sub-menu ${openDropdown === null ? '' : 'active'}`} >
                        <li><a href="web-scraping">Web Scraping</a></li>
                        <li><a href="lead-generation">Lead Generation</a></li>
                        <li><a href="store-location">Store Location Data</a></li>
                        <li><a href="master-data-management">Master Data Management</a></li>
                        <li><a href="industry-specific-data">Industry-Specific Data</a></li>
                        <li><a href="development">Development</a></li>
                      </ul>
                      </li>
                      <li className="scroll-to-section"><a href="about-us" >About Us</a></li>
                      <li className="scroll-to-section"><a href="quality" >Quality</a></li>
                      {/* <li className="scroll-to-section"><a href="blogs1" >Blogs</a></li> */}
                    <li className={`has-sub `}>
                      <a href="#">
                      Blogs
                      </a><i className={`sub-menu ${openDropdown === null ? 'fa-chevron-down' : 'fa-chevron-up'}`} onClick={handleDropdownClick}/>
                      <ul className={`sub-menu ${openDropdown === null ? '' : 'active'}`} >
                        <li><a href="blog-revolutionizing-data-collection">Blog-1</a></li>
                      </ul>
                      </li>
                      <li className="scroll-to-section"><a href="contact-us" >Contact Us</a></li>
                    {/* <li className="scroll-to-section"><a href="#testimonials" >Testimonials</a></li> */}
                    {/* <li><a href="contact-us.html">Contact Support</a></li>  */}
                  </ul>        
                  <div
        className={`menu-trigger ${isActive ? 'active' : ''}`}
        onClick={handleMenuToggle}
      >
                    <span>Menu</span>
                  </div>
                  {/* ***** Menu End ***** */}
                </nav>
              </div>
            </div>
          </div>
        </header>
        {/* ***** Header Area End ***** */}
  
    </Fragment>
}