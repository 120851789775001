
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import LeadGeneration from './pages/LeadGeneration';
import WebScrapping from './pages/WebScrapping';
import StoreLocation from './pages/StoreLocation';
import MasterDataManagement from './pages/MasterDataManagement';
import IndustrySpecificData from './pages/IndustrySpecificData';
import Development from './pages/Development';
import Blog1 from './pages/Blog1.js';
import Quality from './pages/Quality';
import Header from './components/Header';
import Footer from './components/Footer';
import './assets/css/fontawesome.css';
import './assets/css/templatemo-574-mexant.css';
import './assets/css/owl.css';
import './assets/css/animate.css';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Disclaimer from './pages/Disclaimer';




function App() {
  return (
    <div className="App">
    <HelmetProvider>
        <Header/>
        <BrowserRouter>
          <Routes>
          <Route path='/' element={ <Home /> } />
          <Route path='/our-services' element={ <Services /> } />
          <Route path='/about-us' element={ <About /> } />
          <Route path='/contact-us' element={ <Contact /> } />
          <Route path='/web-scraping' element={ <WebScrapping /> } />
          <Route path='/lead-generation' element={ <LeadGeneration /> } />
          <Route path='/store-location' element={ <StoreLocation /> } />
          <Route path='/master-data-management' element={ <MasterDataManagement /> } />
          <Route path='/industry-specific-data' element={ <IndustrySpecificData /> } />
          <Route path='/development' element={ <Development /> } />
          <Route path='/blog-revolutionizing-data-collection' element={ <Blog1 /> } />
          <Route path='/quality' element={ <Quality /> } />
          <Route path='/privacy-policy' element={ <Privacy /> } />
          <Route path='/terms-conditions' element={ <Terms /> } />
          <Route path='/disclaimer' element={ <Disclaimer /> } />
          </Routes>
        </BrowserRouter>
        <Footer />
    </HelmetProvider>
    </div>
  );
}


export default App;
