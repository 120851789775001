import {Fragment} from 'react'
import { Helmet } from 'react-helmet';

// import required modules


export default function Development() {
      
    return <Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FTD | Webpage &amp; Mobile Application</title>
      <meta name="description" content="Transform your ideas into functional web applications with our custom development services. We offer tailored web app solutions to meet your business needs and enhance user experiences." />
      <meta name="keywords" content="web application development, custom web apps, web app design, web app solutions, web development services, mobile app development, iOS app development, Android app development, mobile app solutions, custom mobile apps" />
      {/* <meta name="author" content="Your Name" /> */}
    </Helmet>
        
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="header-text">
                  <h2>Webpage &amp; Mobile Application</h2>
                  <div className="div-dec" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Main Banner Area End ***** */}

        
        <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 offset-lg-1">
              <div className="naccs">
                <div className="tabs">
                  <div className="row">
                    <div className="col-lg-12">
                      <ul className="nacc">
                        <li className="active">
                          <div>
                            <div className="left-image" style={{width:"37%"}}>
                              <img src="assets/images/service-details-06.jpg" alt="web and mobile app" />
                            </div>
                            <div className="right-content">
                              <h4>Web Application Development</h4>
                              <p>At Flawless Tech Data, we create powerful and easy-to-use web applications designed to help your business succeed. Our team uses modern tools and technologies to build web solutions that fit your unique needs.<br /><br /></p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        
        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#43ba7f"}}>Our Web Application Services Include:</h4>
                  <p>
					          <ul>
					          	<li><strong>•	Custom Web Development:</strong> We design and build web applications that meet your specific goals and provide a personalized user experience.</li><br />
					          	<li><strong>•	Responsive Design:</strong> We ensure your web application works smoothly on all devices and screen sizes.</li><br />
					          	<li><strong>•	Backend Solutions:</strong> We create secure and reliable systems that support your web applications.</li><br />
					          	<li><strong>•	Frontend Development:</strong> We design attractive and user-friendly interfaces to make your web application easy to use.</li><br />
					          	<li><strong>•	Integration Services:</strong> We connect your web application with existing systems and services to enhance its functionality.</li><br />
					          	<li><strong>•	Ongoing Support and Maintenance:</strong> We offer continuous support and updates to keep your web application running smoothly.</li><br />
					          </ul>
                    Choose Flawless Tech Data for web application development that blends creativity, functionality, and a great user experience.
				          </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        
        <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 offset-lg-1">
              <div className="naccs">
                <div className="tabs">
                  <div className="row">
                    <div className="col-lg-12">
                      <ul className="nacc">
                        <li className="active">
                          <div>
                            <div className="left-image">
                              <img src="assets/images/service-details-07.png" alt="web and mobile app" />
                            </div>
                            <div className="right-content">
                              <h4>Mobile Application Development</h4>
                              <p>At Flawless Tech Data, we build engaging and user-friendly mobile apps that help boost your brand and connect with your audience. Using advanced tools, we create apps that work well across different devices and operating systems.<br /><br /></p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#43ba7f"}}>Our Mobile Application Services Include:</h4>
                  <p>
					          <ul>
					          	<li><strong>•	Custom Development:</strong> We create mobile apps that are tailored to your business needs and goals, providing a unique solution.</li><br />
					          	<li><strong>•	Cross-Platform Solutions:</strong> We develop apps that function seamlessly on both iOS and Android devices.</li><br />
					          	<li><strong>•	User Experience Design:</strong> We design intuitive and engaging interfaces to improve user satisfaction and interaction.</li><br />
					          	<li><strong>•	Performance Optimization:</strong> We ensure your app runs efficiently with quick load times and reliable performance.</li><br />
					          	<li><strong>•	Integration and Support:</strong> We integrate your app with existing systems and offer ongoing support to keep it updated and functional.</li><br />
					          </ul>
                    Partner with Flawless Tech Data to bring your mobile app ideas to life with top-quality solutions.
				          </p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#43ba7f"}}>Custom Solutions</h4>
                  <p>We offer tailored development services, including API integrations, e-commerce platforms, and content management systems, customized to meet your specific needs.
				          </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>Why Choose Us:</h4>
                  <p>
					        <ul>
					        	<li><strong>• Expertise:</strong> Our team consists of skilled developers and designers knowledgeable in leading technologies.</li><br />
					        	<li><strong>•	Innovation:</strong> We stay up-to-date with the latest advancements to ensure our solutions are effective and cutting-edge.</li><br />
					        	<li><strong>•	Client-Focused:</strong> We work closely with you to understand your needs and deliver solutions that exceed your expectations.</li><br />
					        	<li><strong>•	Quality Assurance:</strong> We follow strict testing and quality standards to ensure our applications and websites are dependable and high-performing.</li>
					        </ul>
				          </p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="center-content">
                  <br /><p>At Flawless Tech Data, we are dedicated to turning your ideas into reality with our comprehensive development services. Partner with us to achieve excellence in digital solutions that promote growth and elevate your brand.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        

    </Fragment>

}