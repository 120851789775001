import {Fragment} from 'react'
import { Helmet } from 'react-helmet';

// import required modules


export default function Terms() {
      
    return <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FTD | Terms and Conditions</title>
        <meta name="description" content="Review our Terms & Conditions to understand your agreement with Flawless Tech Data. Stay informed about your rights and responsibilities." />
        <meta name="keywords" content="dterms and conditions, terms of service, user agreement, legal policy, site terms, FTD policies, Flawless Tech Data policies, Flawless Tech Data terms, FTD terms, conditions of use, legal terms, service agreement, website terms, Flawless Tech Data legal, Flawless Tech Data site policy, FTD site policy, FTD legal" />
      </Helmet>
        
        <div className="page-heading">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="header-text">
                  <h2>Terms &amp; Conditions</h2>
                  <div className="div-dec" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Main Banner Area End ***** */}

         

        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div><b>Effective Date: [1-Sep-2024]</b></div><br /><br />
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>1. Introduction</h4>
                  <p>Welcome to Flawless Tech (“we,” “our,” or “us”). These Terms and Conditions (“Terms”) govern your use of our website [insert website URL] and the services we provide. By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.</p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>2. Use of Our Website and Services</h4>
                  <h3 style={{color:"#43ba7f"}}>2.1. Eligibility</h3>
                  <p>To use our website and services, you must be at least 18 years old or have the consent of a parent or guardian. By using our website and services, you represent that you meet these requirements.</p>
                  <h3 style={{color:"#43ba7f"}}>2.2. User Accounts</h3>
                  <p>You may be required to create an account to access certain features of our website or services. You agree to provide accurate, complete, and up-to-date information when creating an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you suspect any unauthorized use of your account.</p>
                  <h3 style={{color:"#43ba7f"}}>2.3. Prohibited Activities</h3>
                  <p>You agree not to engage in any of the following prohibited activities:</p>
                  <p>
					<ul>
						<li><strong> • Provision of Services:</strong> Illegal Activities: Using our website or services for any unlawful purpose or in violation of any applicable laws or regulations.</li><br />
						<li><strong> • Interference:</strong> Interfering with or disrupting the operation of our website or services or attempting to gain unauthorized access to our systems or networks.</li><br />
						<li><strong> • Impersonation:</strong> Impersonating any person or entity, or falsely claiming an affiliation with any person or entity.</li><br />
						<li><strong> • Abuse of Services:</strong> Using our services in a manner that is abusive, harassing, or harmful to others.</li><br />
					</ul>
				  </p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>3. Intellectual Property</h4>
                  <h3 style={{color:"#43ba7f"}}>3.1. Ownership</h3>
                  <p>All content, trademarks, and other intellectual property on our website, including but not limited to text, graphics, logos, images, and software, are owned by Flawless Tech or its licensors. You may not use, reproduce, modify, distribute, or create derivative works from our intellectual property without our express written permission.</p>
                  <h3 style={{color:"#43ba7f"}}>3.2. License</h3>
                  <p>Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our website and services for personal or internal business purposes. This license does not include any rights to reproduce, modify, distribute, or create derivative works based on our intellectual property.</p>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>4. Privacy</h4>
                  <p>Your use of our website and services is governed by our Privacy Policy [insert link]. By using our website and services, you consent to our collection, use, and disclosure of your information as described in the Privacy Policy.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>5. Third-Party Links</h4>
                  <p>Our website may contain links to third-party websites or services that are not owned or controlled by Flawless Tech. We are not responsible for the content or practices of any third-party websites. You access such third-party websites at your own risk and should review their terms and privacy policies.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>6. Disclaimer of Warranties</h4>
                  <p>Our website and services are provided “as is” and “as available,” without any warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee that our website or services will be uninterrupted, error-free, or free from viruses or other harmful components.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>7. Limitation of Liability</h4>
                  <p>To the maximum extent permitted by law, Flawless Tech and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our website or services. In no event shall our total liability exceed the amount you paid, if any, for accessing our website or using our services.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>8. Indemnification</h4>
                  <p>You agree to indemnify, defend, and hold harmless Flawless Tech and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, costs, or expenses (including reasonable attorneys' fees) arising out of or related to your use of our website or services, or your violation of these Terms.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>9. Force Majeure</h4>
                  <p>Neither Flawless Tech nor you shall be liable for any failure or delay in performing its obligations under these Terms if such failure or delay is due to causes beyond its reasonable control, including but not limited to natural disasters, acts of war, terrorism, pandemics, strikes, riots, governmental actions, or any other events of force majeure. In the event of a force majeure, the affected party shall notify the other party as soon as reasonably practicable and shall take reasonable steps to mitigate the impact of the event.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>10. Modifications to These Terms</h4>
                  <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our website or services after any changes indicates your acceptance of the modified Terms.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>11. Termination</h4>
                  <p>We may suspend or terminate your access to our website or services at any time, with or without cause, if we believe you have violated these Terms or for any other reason. Upon termination, your right to use our website and services will immediately cease.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>12. Governing Law</h4>
                  <p>These Terms and any disputes arising out of or related to them shall be governed by and construed in accordance with the laws of [insert jurisdiction], without regard to its conflict of laws principles.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>13. Dispute Resolution</h4>
                  <p>Any disputes arising out of or related to these Terms or your use of our website or services shall be resolved through binding arbitration in [insert location], in accordance with the rules of [insert arbitration organization]. The arbitration shall be conducted in the English language.</p>
                </div>
              </div>
              <div className="col-lg-9 offset-lg-1">
                <div className="left-content">
                  <h4 style={{color:"#ff511a"}}>14. Contact Information</h4>
                  <p>If you have any questions or concerns about these Terms or our website and services, please contact us at:</p>
                  
                  <h3 style={{color:"#43ba7f"}}>Flawless Tech Data</h3>
                  <p>
					<ul>
						<li><strong> Email:</strong> contact@flawlesstechdata.com</li><br />
						<li><strong> Address:</strong> Flawless Tech Data – 141 Velachery Main Road, East Tambaram, Chennai, Tamil Nadu - 600056</li><br />
					</ul>
				  </p>
                  
 
                </div>
              </div>
              
            </div>
          </div>
        </section>
        

    </Fragment>

}